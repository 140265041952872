<template>
  <div>
    <b-row>
      <b-col
        v-if="$ability.can('read', 'Product_images')"
        lg="1"
        md="2"
      >
        <ProductCardSwiper
          :title="product.Name"
          :images-small="product.images.small"
          :images-full="product.images.full"
        />
        <span
          v-if="product.images.small.some((item) => item.image.includes('/pending/'))"
          class="d-block text-center"
        >
          <feather-icon
            icon="AlertTriangleIcon"
          />
          <br>
          Imagen pendiente
        </span>
      </b-col>
      <b-col
        lg="11"
        md="10"
      >
        <b-row>
          <b-col
            v-if="$ability.can('read', 'Product_brand')"
            md="3"
          >
            <InformationBasicCard
              title="Marca"
              :tags="[{ value: product.name_brand, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_model')"
            md="3"
          >
            <InformationBasicCard
              title="Modelo"
              :tags="[{ value: product.Model, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_category')"
            md="3"
          >
            <InformationBasicCard
              title="Categoría y subcategoría"
              :tags="[{ value: `Categoría: ${product.TrueCategory ? product.TrueCategory.Name : 'Sin categoría'}`, type: 'string' },
                      { value: `Subcategoría: ${product.name_categorie}`, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_code')"
            md="3"
          >
            <InformationBasicCard
              title="Código interno"
              :tags="[{ value: product.Code, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_item')"
            md="3"
          >
            <InformationBasicCard
              title="Item"
              :tags="[{ value: product.Item, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_color_paint') && $ability.can('read', 'Product_color_tapestry')"
            md="3"
          >
            <InformationBasicCard
              title="Color pintura y tapiz"
              :tags="[{ name: product.NamePaint, value: product.CodePaint, type: 'color' },
                      { name: product.NameTapestry, value: product.CodeTapestry, type: 'color' }]"
            />
          </b-col>
          <b-col
            v-if="(product.Type !== 'Nuevo' && product.Type !== 'Remanufacturado') && $ability.can('read', 'Product_prices_list')"
            md="3"
          >
            <InformationBasicCard
              v-if="product.PriceLf && product.PriceDr"
              title="Precios"
              :tags="[
                { value: product.PriceLf, prepend: 'L/F:', type: 'currency' },
                { value: product.PriceDr, prepend: 'D/R:', type: 'currency' },
                { value: product.MaxSuggestedPrice, prepend: 'Precio máximo:', type: 'currency-small' },
                ... product.OfferPrice ? [{ value: product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : []]"
            />
            <InformationBasicCard
              v-else
              title="Precios"
              :tags="[{ value: 'Precio por definir', type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="canShowNormalPricesList && baseListPrice"
            md="3"
          >
            <InformationBasicCard
              v-if="product.PriceMin && product.PriceMax"
              title="Precios"
              :tags="[{ value: product.PriceMin, prepend: 'Mín:', type: 'currency' },
                      { value: product.PriceMax, prepend: 'Máx:', type: 'currency' },
                      ... product.OfferPrice ? [{ value: product.OfferPrice, prepend: 'Oferta:', type: 'currency', style: 'color: #FF7766;' }] : []]"
            />
            <InformationBasicCard
              v-else
              title="Precios"
              :tags="[{ value: 'Precios por definir', type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="canShowPriceWholesaler && baseWholesalerPrice"
            md="3"
          >
            <InformationBasicCard
              v-if="product.PriceMinWholesaler && product.PriceMaxWholesaler"
              title="Mayorista"
              :tags="[{ value: product.PriceMinWholesaler, prepend: 'Mín:', type: 'currency' },
                      { value: product.PriceMaxWholesaler, prepend: 'Máx:', type: 'currency' }]"
            />
            <InformationBasicCard
              v-else
              title="Mayorista"
              :tags="[{ value: 'Precios por definir', type: 'string' }]"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-button
        :variant="visible ? 'flat-primary' : 'flat-secondary'"
        class="btn-icon rounded-circle ml-1 toggle-button"
        @click="collapse"
      >
        <feather-icon :icon="iconToggle" />
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BButton,
} from 'bootstrap-vue'

import ProductCardSwiper from '@/components/swipers/ProductCardSwiper.vue'
import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    ProductCardSwiper,
    InformationBasicCard,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    iconToggle: {
      type: String,
      default: 'ChevronDownIcon',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    canShowNormalPricesList() {
      return (this.product.Type === 'Nuevo' || this.product.Type === 'Remanufacturado')
        && this.$ability.can('read', 'Product_prices_list')
    },
    canShowPriceWholesaler() {
      return this.product.PricesDefinedWholesaler
        && (this.product.Type === 'Nuevo' || this.product.Type === 'Remanufacturado')
        && this.$ability.can('read', 'Product_prices_wholesaler')
    },
    normalPricesDefined() {
      return this.product.PriceMin && this.product.PriceMax
    },
    wholesalerPricesDefined() {
      return this.product.PriceMinWholesaler && this.product.PriceMaxWholesaler
    },
    baseListPrice() {
      if (this.userData.Role !== 'Mayorista') {
        return true
      }

      return this.checkPrices
    },
    baseWholesalerPrice() {
      if (this.userData.Role !== 'Mayorista') {
        return true
      }

      return !this.checkPrices
    },
    checkPrices() {
      if (!this.normalPricesDefined && this.wholesalerPricesDefined) {
        return false
      }

      if (this.normalPricesDefined && this.wholesalerPricesDefined) {
        return false
      }

      if (!this.normalPricesDefined && !this.wholesalerPricesDefined) {
        return true
      }

      if (this.normalPricesDefined && !this.wholesalerPricesDefined) {
        return true
      }

      return true
    },
  },
  methods: {
    collapse() {
      this.$emit('toggle-collapse')
    },
  },
}
</script>

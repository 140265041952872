<template>
  <div
    class="d-flex"
    :class="canApplyMargin ? 'my-50' : ''"
  >

    <!-- #region::Métrica para el stock GDL -->
    <div
      v-if="totalGDL"
      class="pill-alert alert-cyan"
      role="alert"
    >
      {{ totalGDL }} Bodega GDL
    </div>
    <!-- #endregion::Métrica para el stock GDL -->

    <!-- #region::Métrica para el stock CDMX -->
    <div
      v-if="totalCDMX"
      class="pill-alert alert-green"
      role="alert"
    >
      {{ totalCDMX }} Bodega CDMX
    </div>
    <!-- #endregion::Métrica para el stock CDMX -->

    <!-- #region::Métrica para el stock Preventa -->
    <div
      v-if="totalPresale"
      class="pill-alert alert-sapphire"
      role="alert"
    >
      {{ totalPresale }} Preventa
    </div>
    <!-- #endregion::Métrica para el stock Preventa -->

    <!-- #region::Métrica para el stock Tienda -->
    <div
      v-if="totalStore"
      class="pill-alert alert-yellow"
      role="alert"
    >
      {{ totalStore }} Tienda
    </div>
    <!-- #endregion::Métrica para el stock Tienda -->

  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    totalGDL() {
      return this.product.totalGDL
    },
    totalCDMX() {
      return this.product.totalCDMX
    },
    totalPresale() {
      return this.product.totalPresale
    },
    totalStore() {
      return this.product.totalStore
    },
    /**
     * Verifica si se puede aplicar un margen a las métricas
     */
    canApplyMargin() {
      return this.totalGDL || this.totalCDMX || this.totalPresale || this.totalStore
    },
  },
}
</script>
